@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@600&display=swap');
.main-content{
    position: relative;
    top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 80vw;
    left: 10vw;
    overflow-x: hidden;
}
.content{
    margin-top: 10px;
    height: 80vh;
    width: 480px;
    margin-left: 10vw;
    overflow: hidden;
}
.quote{
    font-family: Poppins;
    width: 480px;
    font-size: 60px;
    word-spacing: -1px;
    letter-spacing: -2px;
    margin-bottom: 30px;
    margin-top: 50px;
}
.smallText{
    font-family: Inter;
    font-size: 14px;
    color: black;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 300;
    font-style: italic;
}
button{
    font-family: Inter;
    font-size: 14px;
    font-weight: 800;
    text-decoration: none;
    color: black;
    height: 45px;
    width: 120px;
    border-radius: 7px;
    margin:5px;
}
.Collections{
    background-color: #3164f4;
    border-color: #3164f4;
    border-width: 0px;
    color: white;
}
.Contact{
    border: black 1px solid;
    color: black;


}
.buttons{
    display: flex;
    justify-content: center;
    align-items: center;
}
.image{
    margin-top: 35px;
    object-fit: cover;
    margin-left: -100px;
}
#store{
    
    height: 480px;
    width: 500px;
}
.sub-content{
    position: relative;
    top: 40px;
}
#text-color-grey{
    color: gray;
    font-weight: 600;
    font-size: 16px;
}
.logo-repeat{
    position: absolute;
    top: -30px;
    display: flex;
    flex-direction: row;
    left: -50px;
    overflow-x: hidden;
    width: 100vw;
}
.logo{
    height: 250px;
    width: 230px;
    opacity: 0.85;
}

@media screen and (max-device-width: 480px) {
    .main-content{
        height: max-content;
        top: 12vh;
    }
    .image{
        display:none;
    }
    .content{
       margin-left:5vw;
   
    }
    .quote{
        font-size:14vw;
        width: 80vw;
        word-wrap: break-word;
        white-space: pre-wrap;
        word-break: break-word;
    }
    .smallText{
        width: 70vw;
        font-size: 12px;
        
    }
    button{
        width:max-content;
        font-size: 12px;
    }
    .sub-content{
        display: none;
    }
    #text-color-grey{
        text-align: center;
        padding: 10vw;
    }
    .logo-repeat{
        position: relative;
        margin-top: -5vw;
        left: -30px;
        overflow: visible;
    }
    .logo{
        height: 100px;
        width: 100px;
    }
  }
  
  @media screen and (min-device-width:481px) and (max-device-width: 1090px) {
    .image{
        display:none;
    }
    .main-content{
        height: max-content;
        left: 10vw;
    }
    .smallText{
        width: 60vw;
        font-size: 14px;
        
    }
    button{
        width:max-content;
        font-size: 12px;
    }
    .buttons{
        display: flex;
        justify-content:center;
        margin: auto;
        width: 50%;
    }
    .sub-content{
        position: absolute;
        top: 50vh;
        margin-left: 5vw;
    }
    #text-color-grey{
        text-align: center;
        padding: 12vw;
        font-size: 18px;
    }
    .logo-repeat{
        position: relative;
        margin-top: 20px;
        left: -30px;
        overflow: visible;
    }
    .logo{
        height: 100px;
        width: 100px;
    }
  }