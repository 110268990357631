@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');

#collection{
    position: relative;
    top: 55vh;
    margin: auto;
    text-align: center;
    margin-top: 0;
}
.image{
    width: 300px;
    height: 450px;
}
#template{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 15vw;
    margin-right: 15vw;
}
a{
    text-decoration: none;
}
.temp{
    margin: 0;
}
.tag{
    border-radius: 0;
    border: none;
    background-color: #ededed;
    color:black;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 300;
    width: max-content;
}
.icon{
    display: none;
}
@media screen and (max-width:480px){
    #collection{
        top: 30em;
    }
    #template{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 0;
    }
    .icon{
        display: inline;
        height: 25px;
        width: 25px;
        color: #3164f4;
        margin-right: 5px;
        margin-top: 10px;

    }
    .tag{
        margin: 0;
    }
    .buticon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        border: #3164f4 2px solid;
        border-radius: 7px;
        margin-left: 20vw;
        margin-right: 20vw;
    }
}

@media screen  and  (min-device-width:481px) and (max-width:1090px){
    #collection{
        top: 30em;
    }
    #template{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    .icon{
        display: inline;
        height: 25px;
        width: 25px;
        color: #3164f4;
        margin-right: 5px;
        margin-top: 10px;

    }
    .tag{
        margin: 0;
    }
    .buticon{
        display: flex;
        flex-direction: row;
        justify-content: center;
        border: #3164f4 2px solid;
        border-radius: 7px;
        margin-left: 10vw;
        margin-right: 10vw;
        }
    
}