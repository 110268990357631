@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@600&display=swap');
#about{
    position: relative;
    top: 35vh;
    margin: auto;
    text-align: center;
    
}
#heading{
    font-family: Poppins;
    font-size:48px;
    word-spacing: -1px;
    letter-spacing: -1px;
    margin-bottom: 30px;
    font-weight: 600;
}
#para{
    margin: auto;
    width: 60%;
    font-family: Inter;
    font-size: 14px;
}
#image{
    margin: auto;
    width: 60%;
    height: 60vh;
    padding-top: 20px;
    object-fit: cover;
}

@media screen and (max-width:480px){
    #about{
        position: absolute;
        top: 80vh;
    }
    #heading{
        font-size: 32px;
        margin-top: 50px;
    }
    #para{
        font-size: 12px;
    }
    #image{
        height: 200px;
        object-fit: cover;
    }
}
@media screen and (min-device-width:481px) and (max-device-width: 1090px) {
    #about{
        position: absolute;
        top: 60vh;
    }
    #image{
        height: 400px;
        object-fit: cover;
    }
}