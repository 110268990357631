@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
.header{
    position: fixed;
    z-index: 10000;
    top: 0;
    height: 80px;
    width: 1440px;
    display: flex;
    flex-direction: row;
    padding-left:10px;
    align-items: center;
}
.link{
    font-family: Inter;
    font-weight: 600;
    text-decoration: none;
    color: #262626;
    font-size: 12px;
    padding-right: 40px;
}
.logo{
    height: 280px;
    width: 250px;
}

.header .icon {
  display: none;
}

@media screen and (max-width:480px) {
  .link{
    font-size: 10px;
    padding-right: 5px;
  }
}



#home{
  color: #3164f4;
}
