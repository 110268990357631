#Testimonials{
    position: relative;
    top: 115vh;
    margin: 0;
    text-align: center;
}
.swiper {
    width: 50vw;
    height: 30vh;
  }

.swiper-slide {
    text-align: center;
    font-size: 0.63vw;
    
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    border-radius: 12px;
}
  
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width:480px){
    #Testimonials{
      top: 35em;
    }
    .swiper-slide{
      border: 1px solid white;
      font-size: 6px;
    }
    .swiper {
      width: 90vw;
    }
  }

  @media screen  and  (min-device-width:481px) and (max-width:1090px){
      #Testimonials{
        top: 42em;
      }
      .swiper-slide{
        border: 5px solid white;
        font-size: 14px;
      }
  }
  