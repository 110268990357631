#Kidswear{
    /* to be removed */
    position: relative;
    top: 10vh;
    margin: 0;
    text-align: center;
    margin-left: 10vw;
    margin-right: 10vw;
    
}
#men-cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.mens-img{
    height: 300px;
    width: 250px;
    object-fit: cover;
}