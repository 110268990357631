@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@600&display=swap');
#Contact{
    position: relative;
    top: 125vh;
    margin: auto;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
}
#main--content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 25vw;
    margin-right: 25vw;
}
#map--and--contact{
    text-align: left;
    
}
#send--message{
    text-align: left;
}
#contact--number{
    display: flex;
    flex-direction: row;
}
.inner-contact-details{
    margin-top: -3px;
    margin-left: 5px;
    margin-bottom: 4px;
}
#address a{
    text-decoration: none;
    color: black;
    margin-left: 10px;
}
#address{
    margin-top: 15px;
    margin-bottom: 15px;
    width:20vw;
    display: flex;
    flex-direction: row;
}
.contact--icon{
    margin-top: 3px;
    color: #3164f4;
}
#send--message{
    font-family: Inter;
    font-size: 14px;
    margin: 0;
}
#send--message p{
    font-style: italic;
    text-align: center;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 16px;
}
#send--message form{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: -20px;
}
#send--message form label{
   margin-bottom: -3px;
}
#send--message form input{
    padding: 10px;
    border-radius: 5px;
    border: 2px solid white;
    width: 15vw;
}
#send--message form textarea{
    padding: 10px;
    border-radius: 5px;
    border: 2px solid white;
    width: 15vw;
}
#send--message form button{
    background-color: #3164f4;
    color: white;
    width: 16vw;
    border: 2px solid #3164f4;
    height: 40px;
}
@media screen and (max-width:480px){
    #Contact{
        top: 42em;
    }
    #main--content{
        flex-direction: column;
    }
    #map--and--contact iframe{
        width: 220px;
        height: 200px;
    }
    #map--and--contact a{
        margin-left: 10px;
    }
    #address{
        width: 65vw;
    }
    #send--message {
        margin-top: 40px;
    }
    #send--message p{
        width: 60vw;
    }
    #send--message form input{
        width: 60vw;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #send--message form textarea{
        width: 60vw;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #send--message form button{
        width: 60vw;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
@media screen  and  (min-device-width:481px) and (max-width:1090px){
    #Contact{
        top: 45rem;
    }
    #map--and--contact iframe{
        width: 300px;
    }
    #main--content{
        margin-left: 10vw;
        margin-right: 10vw;
    }
    #address{
        width: 40vw;
    }
    #send--message form input{
        width: 30vw;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #send--message form textarea{
        width:30vw;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    #send--message form button{
        width: 30vw;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
