#Footer{
    position: relative;
    top: 130vh;
    margin: 0;
    text-align: center;
    margin-left: 15vw;
    margin-right: 15vw;
}
#footer--content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.inner--content{
    display: flex;
    flex-direction: column;
}
.link{
    padding: 10px;
}
.footer--logo{
    position: absolute;
    top: -50px;
    left: -20px;
    height: 20vh;
    width: 15vw;
}
#copyright{
    margin-top: 50px;
    
    font-family: Inter;
    font-weight: 600;
    text-decoration: none;
    color: #262626;
    font-size: 10px;
}
#copyright p{
    margin: 0;
}
@media screen and (max-device-width: 480px) {
    #Footer{
        top: 42em;
        text-align: center;
    }
    #footer--content{
        flex-direction: column-reverse;
    }
    #link{
        font-size: 10px;
        font-weight: 400;
    }
    #copyright{
        margin: 0;
        text-align: center;
    }
    .footer--logo{
        display: none;
    }
}

@media screen  and  (min-device-width:481px) and (max-width:1090px){
    #Footer{
      top: 47em;
    }
    .footer--logo{
        top: -70px;
    }
    #copyright{
        margin-top: 70px;
        margin-left: 30px;
    }
    #link{
        font-size: 10px;
    }
}