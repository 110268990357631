#Gallery{
    position: relative;
    top: 100vh;
    margin: auto;
    text-align: center;
}

.image-gallery-image img{
  height: 500px;
  width: 500px;
  object-fit: cover;
}

@media screen and (max-width:480px) {
  #Gallery{
    top: 34em;
  }
  .image-gallery-image img{
    height: 300px;
    width: 300px;
  }
}

@media screen  and  (min-device-width:481px) and (max-width:1090px){
  #Gallery{
      top: 39em;
  }
}