@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');
#brands{
    position: relative;
    top: 75vh;
    margin-left: 5vw;
    margin-right: 5vw;
    text-align: center;
}
#cards{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.card{
    background-color: #e5e5e5;
    border-radius: 16px;
    width: 300px;
    height: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
}
img{
    height: 130px;
    width: 250px;
    object-fit:contain;
    padding: 15px;
}

@media screen and (max-width:480px){
    #brands{
        top: 32em;
    }
    .card{
        width: 100px;
        height: 100px;
    }
    img{
        height: 80px;
        width: 80px;
    }
}
@media screen  and  (min-device-width:481px) and (max-width:1090px){
    #brands{
        top: 35em;
    }
}