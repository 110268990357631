@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@600&display=swap');
#Team{
    position: relative;
    top: 90vh;
    margin: auto;
    text-align: center;
}
.temp-team{
    margin-right: 5vw;
}
.frame{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.name{
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
}
.designation{
    font-family: Poppins;
    font-weight: 100;
    font-size: 14px;
    margin-top: -10px;
}
.potrait{
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

@media screen and (max-width:480px){
    #Team{
        position: relative;
        top: 34em;
    }
    .frame{
        flex-direction: column;
    }
    .potrait{
        width: 200px;
        height: 200px;
    }
    .temp-team{
        margin: 0;
    }
}

@media screen  and  (min-device-width:481px) and (max-width:1090px){
    #Team{
        top: 37em;
    }
    .potrait{
        width: 250px;
        height: 250px;
    }
}